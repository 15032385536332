import { useEffect, useMemo } from 'react';

import { Attributes, BrowserCookieStickyBucketService, GrowthBookProvider } from '@growthbook/growthbook-react';
import Cookies from 'js-cookie';

import { useExperimentsGetExperimentByFeatureId } from '@dc/hooks';
import useDC from '@dc/useDC';
import {
  DEVICE_ID_COOKIE_AGE,
  GrowthBook,
  STICKY_BUCKET_COOKIE_AGE,
  getGrowthbookTrackingEvent,
  getStickyBucketAssignmentDocs,
} from '@dxp-growthbook';
import { useRouter } from '@dxp-next';
import { pushToDataLayer } from '@tracking';

interface ExperimentProviderProps {
  featureId: string;
  children: React.ReactNode;
  attributes?: Attributes;
}

export const ExperimentProvider = ({ children, featureId, attributes }: ExperimentProviderProps) => {
  const router = useRouter();
  const dc = useDC();
  const { data } = useExperimentsGetExperimentByFeatureId(
    { featureId },
    {
      loadingTimeout: 1000,
    },
  );

  const growthbookInstance = useMemo(() => {
    const features = data?.definition ? { [featureId]: JSON.parse(data.definition) } : {};
    const path = router?.activePath;
    return new GrowthBook({
      attributes: { path, ...dc, ...attributes },
      getDeviceId: cookieName => Cookies.get(cookieName),
      stickyBucketAssignmentDocs: getStickyBucketAssignmentDocs(Cookies.get()),
      stickyBucketService: new BrowserCookieStickyBucketService({
        jsCookie: Cookies,
        cookieAttributes: { expires: STICKY_BUCKET_COOKIE_AGE },
      }),
      persistDeviceId: (cookieName, deviceId) => Cookies.set(cookieName, deviceId, { expires: DEVICE_ID_COOKIE_AGE }),
      trackingCallback: (experiment, result, deviceId) => {
        pushToDataLayer(getGrowthbookTrackingEvent(experiment, result, deviceId));
      },
    }).initSync({
      payload: { features },
    });
  }, [data, attributes, featureId, router?.activePath, dc]);

  useEffect(() => {
    const updateGrowthBookURL = () => {
      growthbookInstance.setURL(window.location.href);
    };
    router.events.on('routeChangeComplete', updateGrowthBookURL);
    return () => router.events.off('routeChangeComplete', updateGrowthBookURL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <GrowthBookProvider growthbook={growthbookInstance}>{children}</GrowthBookProvider>;
};
