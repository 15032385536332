import { IncomingMessage, ServerResponse } from 'http';

import {
  Options,
  FeatureDefinition,
  TrackingCallback as OriginalTrackingCallback,
  StickyAttributeKey,
} from '@growthbook/growthbook/dist/types/growthbook';
import {
  GrowthBook as OriginalGrowthBook,
  StickyBucketService,
  Attributes,
  StickyAssignmentsDocument,
} from '@growthbook/growthbook-react';
import { v4 as uuidv4 } from 'uuid';

import logger from '@common/log';
import { Customers_Experiments_ExperimentResponseModel } from '@monorepo-types/dc';
import { ExperimentEvent } from '@tracking';

export const DEVICE_ID_COOKIE_AGE = 365;
export const DEVICE_ID_COOKIE_NAME = 'gb_device_id';

const FEATURE_DATA_DELIMITER = '|';
const CONTROL_VALUE_TO_SKIP = 'control';

// The `full page` tag is used to identify the experiments that are meant to change the whole page content, based on the served variant.
// The tag is required to be applied to the feature on the Growthbook side, for the correct experiment work.
const FULL_PAGE_TEST_TAG = 'full page';
const FULL_PAGE_TEST_PATH_PREFIX = '_test';

export const STICKY_BUCKET_COOKIE_PREFIX = 'gbStickyBuckets__';
export const STICKY_BUCKET_COOKIE_AGE = 365;

type FeatureName = string;
type FeatureValue = string;

type FeatureDefinitions = Record<FeatureName, FeatureDefinition>;

type GetDeviceId = (cookieName: string) => string | undefined;
type PersistDeviceId = (cookieName: string, deviceId: string) => void;

export type AllFeatureValues = Record<FeatureName, FeatureValue>;
export type TrackingCallback = (
  ...args: [...Parameters<OriginalTrackingCallback>, deviceId?: string]
) => ReturnType<OriginalTrackingCallback>;

type FeatureFilters = {
  path?: string;
  tag?: string;
  attributes?: Attributes;
};

type GrowthBookContext = Omit<Options, 'trackingCallback'> & {
  getDeviceId: GetDeviceId;
  persistDeviceId: PersistDeviceId;
  trackingCallback?: TrackingCallback;
};

type GetCookie = (request: IncomingMessage, key: string) => string | undefined;
type SetCookie = (response: ServerResponse, key: string, value: string, age: number) => void;

export class CustomStickyBucketService extends StickyBucketService {
  private request: IncomingMessage;
  private response: ServerResponse;
  private cookieMaxAge: number;
  private getCookie: GetCookie;
  private setCookie: SetCookie;

  constructor({
    prefix = STICKY_BUCKET_COOKIE_PREFIX,
    request,
    response,
    cookieMaxAge = STICKY_BUCKET_COOKIE_AGE,
    getCookie,
    setCookie,
  }: {
    prefix?: string;
    request: IncomingMessage;
    response: ServerResponse;
    cookieMaxAge?: number;
    getCookie: GetCookie;
    setCookie: SetCookie;
  }) {
    super({ prefix });
    this.request = request;
    this.response = response;
    this.cookieMaxAge = cookieMaxAge;
    this.getCookie = getCookie;
    this.setCookie = setCookie;
  }

  async getAssignments(attributeName: string, attributeValue: string): Promise<StickyAssignmentsDocument | null> {
    const key = this.getKey(attributeName, attributeValue);
    let doc: StickyAssignmentsDocument | null = null;

    try {
      const raw = this.getCookie(this.request, key) || '{}';
      const data = JSON.parse(raw);

      if (data.attributeName && data.attributeValue && data.assignments) {
        doc = data;
      }
    } catch (e) {
      logger.error('Error loading sticky bucket cookie:', `${e}`);
      return null;
    }

    return await Promise.resolve(doc);
  }

  async saveAssignments(doc: StickyAssignmentsDocument): Promise<void> {
    const key = this.getKey(doc.attributeName, doc.attributeValue);
    const str = JSON.stringify(doc);

    try {
      this.getCookie(this.request, key);
      this.setCookie(this.response, key, str, this.cookieMaxAge);
    } catch (e) {
      logger.error('Error saving sticky bucket cookie:', `${e}`);
    }
    return await Promise.resolve();
  }
}

export class GrowthBook extends OriginalGrowthBook {
  private _getDeviceId: GetDeviceId;
  private _persistDeviceId: PersistDeviceId;

  constructor(context: GrowthBookContext) {
    const attributes = context.attributes || {};
    const { trackingCallback, ...rest } = context;

    super({
      ...rest,
      trackingCallback: (experiment, result) => context?.trackingCallback?.(experiment, result, attributes.deviceId),
    });

    this._getDeviceId = context.getDeviceId;
    this._persistDeviceId = context.persistDeviceId;

    if (!attributes.deviceId) attributes.deviceId = this._getOrSetDeviceId();

    this.setAttributes({ ...attributes });
  }

  private _getFeaturePathRegex = (feature: FeatureDefinition) => {
    for (const rule of feature.rules || []) {
      // @ts-ignore-next-line - growthbook types shaped in a way that makes it hard to access the condition
      if (rule.condition?.path?.$regex) return rule.condition?.path?.$regex;
    }
  };

  private _getFullPagePath = (featureName: string, featureVariant: string, targetPath: string, pathSuffix = '') => {
    return `${targetPath}${FULL_PAGE_TEST_PATH_PREFIX}/${featureName}/${featureVariant}/${pathSuffix}`;
  };

  private _getOrSetDeviceId = () => {
    let deviceId = this._getDeviceId(DEVICE_ID_COOKIE_NAME);

    if (deviceId) return deviceId;

    deviceId = uuidv4();
    this._persistDeviceId(DEVICE_ID_COOKIE_NAME, deviceId);

    return deviceId;
  };

  /**
   * Loops through the given feature definitions and returns a new object containing only the features that match the given
   * filters.
   *
   * @param allFeatures - The feature definitions to filter.
   * @param filters - The filters to apply to the features.
   * @param filters.path - The path to filter the features by. If a feature has no path targeting condition, it will also be included.
   * @param filters.tag - The tag to filter the features by. If a feature has no tags, it will not be included.
   *
   * @returns A new object containing the filtered features.
   */
  private _getFilteredFeatures = (allFeatures: FeatureDefinitions, filters: FeatureFilters): FeatureDefinitions => {
    const filteredFeatures: FeatureDefinitions = {};

    Object.keys(allFeatures).forEach(featureId => {
      const feature = allFeatures[featureId];

      if (filters.path && !this._doesFeatureMatchPath(feature, filters.path)) return;
      if (filters.tag && !this._doesFeatureMatchTag(feature, filters.tag)) return;
      if (filters.attributes && !this._doesFeatureMatchTargetingAttributes(feature, filters?.attributes)) return;

      filteredFeatures[featureId] = allFeatures[featureId];
    });

    return filteredFeatures;
  };

  private _cachedTargetingConditionsRegex: Record<string, RegExp> = {};

  /**
   * Check if the given feature matches the given path. If the feature has no path targeting condition, it will always match.
   * This currently only supports the $regex condition for path targeting.
   *
   * @param feature - The feature to check.
   * @param path - The path to check against.
   * @returns whether the feature matches the path. If the feature has no path targeting condition, it will always match.
   */
  private _doesFeatureMatchPath = (feature: FeatureDefinition, path: string) => {
    const targetingConditionRegex = this._getFeaturePathRegex(feature);

    // The feature does not have a path targeting condition, so it applies to all paths
    if (!targetingConditionRegex) return true;

    if (!this._cachedTargetingConditionsRegex[targetingConditionRegex]) {
      try {
        this._cachedTargetingConditionsRegex[targetingConditionRegex] = new RegExp(targetingConditionRegex);
      } catch (error) {
        logger.info('qBSUrR', 'Invalid regex for targeting condition', { path, targetingConditionRegex });
        return false;
      }
    }

    return this._cachedTargetingConditionsRegex[targetingConditionRegex].test(path);
  };

  /**
   * Check if the given feature's targeting attributes match the SSR attributes.
   * If experiment has a targeting condition that is not present in the SSR attributes, it will not match and tracking callback will not be triggered.
   *
   * @param feature - The feature to check.
   * @param attributes - The targeting attributes to check for.
   * @returns whether the feature has the matching attributes.
   */

  private _doesFeatureMatchTargetingAttributes = (feature: FeatureDefinition, attributes: Attributes) => {
    const allowedSSRAttributes = Object.keys(attributes)?.map(key => key);
    for (const rule of feature.rules ?? []) {
      for (const [key] of Object.entries(rule?.condition ?? [])) {
        if (!allowedSSRAttributes.includes(key)) return false;
      }
    }
    return true;
  };

  /**
   * Check if the given feature has the given tag. If the feature has no tags, it will not match.
   *
   * @param feature - The feature to check.
   * @param tag - The tag to check for.
   * @returns whether the feature has the tag.
   */
  private _doesFeatureMatchTag = (feature: FeatureDefinition, tag: string) => {
    return feature.tags?.some(featureTag => featureTag.toLowerCase() === tag.toLowerCase());
  };

  /**
   * Filter features based on the requested path and tag.
   * @param {string} path - The requested path.
   * @param {string} tag - (optional) The growthbook tag to filter the features by.
   * @returns {AllFeatureValues} The matched feature values for the requested path.
   */
  public getAllFeatureValues = (filters?: FeatureFilters) => {
    const allFeatures = this.getFeatures();

    const featureValues: AllFeatureValues = {};
    const filteredFeatures = this._getFilteredFeatures(allFeatures, filters ?? {});

    Object.keys(filteredFeatures).forEach(featureId => {
      featureValues[featureId] = this.getFeatureValue(featureId, '');
    });

    return featureValues;
  };

  /**
   * Returns the modified path for the current path, if it matches a feature with the `full page` tag.
   *
   * If the currently requested path matches a feature with the `full page` tag, the path will be modified to include the
   * experiment target path, containing the feature name and value. The modified path will be used to serve the experiment
   * variant.
   *
   * Full page testing can be applied with a `full page` tag and will support the following scenarios through targeting
   * conditions:
   *
   * 1. Single page experiment - the experiment is applied to a single page.
   *   The feature $regex path targeting condition should be set to the exact path of the page or should not contain more than
   *   1 capturing group.
   * 2. SPA experiment - the experiment is applied to a SPA that has multiple child pages.
   *   The feature $regex path targeting condition should contain 2 capturing groups, one for the entry point of the SPA and
   *   one for the rest of the path.
   *
   * The regex and resulting modified path for both scenarios will look as followes:
   * regex: ^\/entry-point\/(.*)?$
   * modified path: /entry-point/_test/{featureName}/{featureValue}/
   *
   * regex: ^\/(entry-point)\/(.*)?$
   * modified path: /$1/_test/{featureName}/{featureValue}/$2/
   *
   * The function will return an empty string if the path does not match any feature with the `full page` tag.
   *
   * @param path - The requested path.
   * @returns The path variant specific for the full page experiment.
   */
  public getFullPageTestPath = (path: string): string => {
    const allFeatures = this.getFeatures();
    const features = this._getFilteredFeatures(allFeatures, { path, tag: FULL_PAGE_TEST_TAG });

    for (const [featureName, feature] of Object.entries(features)) {
      const featureValue = this.getFeatureValue(featureName, '');

      // Skip the control value, as it should not modify the path
      if (featureValue.toLowerCase() === CONTROL_VALUE_TO_SKIP) break;

      const targetingConditionRegex = this._getFeaturePathRegex(feature);
      const regex = new RegExp(targetingConditionRegex);

      const [fullMatch = '', capturedBasePath = '', capturedSuffix = ''] = path.match(regex) || [];
      // This supports both single page and SPA experiments, by trying to get the capture groups as well as falling back onto
      //  the full path if no suffix capture group was found.
      const basePath = capturedSuffix ? capturedBasePath : fullMatch;
      return this._getFullPagePath(featureName, featureValue, basePath, capturedSuffix);
    }

    return '';
  };
}
/**
 * Transform features to compatible format for GrowthBook SDK.
 *
 * The features that are matched are expected to have a 00000|feature-name format, which is a by pipe
 * (|) concatinated string of the following values
 *  * 00000: azure story id
 *  * feature-name: the actual a/b feature name that will be sent to sitecore alongside its value
 *
 * @param {Customers_Experiments_ExperimentResponseModel[]} features as returned by DC's call to /experiments
 */

export const transformFeatures = (
  features: Array<Customers_Experiments_ExperimentResponseModel>,
): Record<string, FeatureDefinition> => {
  const transformedFeatures: Record<string, FeatureDefinition> = {};

  features.forEach(feature => {
    const { name } = getFeatureMetaData(feature);

    // Skip experiments that:
    //  1. Do not have a definition set, as these are likely for other environments.
    //    (Growthbook returns features on prod with empty defintiions that are meant for acc, for example)
    //  2. Do not adhere to the agreed-upon naming convention.
    if (!feature.definition || !name) return;

    transformedFeatures[name] = {
      ...JSON.parse(feature.definition ?? '{}'),
      tags: feature.tags,
    };
  });

  return transformedFeatures;
};

export const getFeatureMetaData = (feature: Customers_Experiments_ExperimentResponseModel): { name: string } => {
  const featureId = feature?.id ?? '';
  // TODO: reverse() is used for the backward compatibility. It should be cleaned up after all features named according to the desired naming convention.
  // Current naming convention: 00000|_resource_path_|featureName
  // Desired naming convention: 00000|featureName
  const [name] = featureId.split(FEATURE_DATA_DELIMITER).reverse();
  return { name };
};

export const getGrowthbookTrackingEvent: (...args: Parameters<TrackingCallback>) => ExperimentEvent = (
  experiment,
  result,
  deviceId,
) => {
  return {
    event: 'experiment_viewed',
    experiment_id: experiment.key,
    variation_id: result.variationId,
    device_id: deviceId,
  };
};

export const getStickyBucketAssignmentDocs = (
  cookieSource: string | Record<string, string> | undefined,
): Record<StickyAttributeKey, StickyAssignmentsDocument> => {
  const stickyBucketAssignmentDocs: Record<StickyAttributeKey, StickyAssignmentsDocument> = {};

  try {
    const cookies =
      typeof cookieSource === 'string'
        ? cookieSource.split(/;\s*/).map(cookie => cookie.split('='))
        : Object.entries(cookieSource ?? {});

    for (const [key, value] of cookies) {
      if (key.startsWith(STICKY_BUCKET_COOKIE_PREFIX)) {
        const keyWithoutPrefix = key.replace(STICKY_BUCKET_COOKIE_PREFIX, '');
        const [attributeName, attributeValue] = keyWithoutPrefix.split('||');

        if (value) {
          const decodedValue = decodeURIComponent(value);
          const parsedValue = JSON.parse(decodedValue);

          stickyBucketAssignmentDocs[`${attributeName}||${attributeValue}`] = {
            attributeName: parsedValue.attributeName,
            attributeValue: parsedValue.attributeValue,
            assignments: parsedValue.assignments,
          };
        }
      }
    }
  } catch (error) {
    return stickyBucketAssignmentDocs;
  }

  return stickyBucketAssignmentDocs;
};
